import React, {forwardRef, useEffect, useState} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {motion} from 'framer-motion';
import {AppBarHeight} from "../App";
import {Link} from "react-scroll";

const HeroSection = forwardRef((props, ref) => {
    const [bgImage, setBgImage] = useState('');
    const [photographer, setPhotographer] = useState('');
    const [photographerLink, setPhotographerLink] = useState('');


    useEffect(() => {
        const UNSPLASH_ACCESS_KEY = "9egJG3KlYb6jKtq8ZOHUtGw2AI4kKOyBUFSVgWchMSs";
        fetch(`https://api.unsplash.com/photos/random?query=nature,dark&client_id=${UNSPLASH_ACCESS_KEY}`)
            .then((response) => response.json())
            .then((data) => {
                setBgImage(data.urls.regular);
                setPhotographer(data.user.name);
                setPhotographerLink(data.user.links.html + '?utm_source=cloelsa&utm_medium=referral');
            })
            .catch((error) => console.error("Error fetching Unsplash image:", error));
    }, []);

    return (
        <div id="hero" ref={ref}>
            <Box
                sx={{
                    height: '100vh',
                    backgroundImage: bgImage ? `url(${bgImage})` : 'none',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    transition: 'background-image 0.5s ease-in-out', // Smooth transition
                    position: 'relative', // Ensure attribution box is positioned correctly
                }}
            >
                <motion.div
                    initial={{y: -250}}
                    animate={{y: -10}}
                    transition={{type: 'spring', stiffness: 100}}
                >
                    <Typography variant="h2" color="primary.contrastText">
                        Welcome to Cloelsa
                    </Typography>
                    <Typography variant="h5" color="primary.contrastText" sx={{my: 2}}>
                        Building Solutions Around Artificial Intelligence
                    </Typography>
                    <Link key={"features"} to={"features"} smooth={true} duration={500} offset={-AppBarHeight}>
                        <Button variant="contained" color="primary" size="large">
                            Learn More
                        </Button>
                    </Link>
                </motion.div>
                {/* Attribution Box */}
                {photographer && (
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 10,
                            right: 10,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            padding: '5px 10px',
                            borderRadius: '5px',
                        }}
                    >
                        <Typography variant="caption">
                            Photo by{' '}
                            <a
                                href={photographerLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'white', textDecoration: 'underline' }}
                            >
                                {photographer}
                            </a>{' '}
                            on{' '}
                            <a
                                href="https://unsplash.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'white', textDecoration: 'underline' }}
                            >
                                Unsplash
                            </a>
                        </Typography>
                    </Box>
                )}
            </Box>
        </div>
    );
});

export default HeroSection;
