import React, {forwardRef} from 'react';
import {Box, Card, CardContent, Grid, Typography} from '@mui/material';
import {motion} from 'framer-motion';
import {CheckCircleOutline, FavoriteBorder} from '@mui/icons-material';
import MicIcon from '@mui/icons-material/Mic';

const features = [
  { icon: <MicIcon />, title: 'Punchlinator', description: 'AI Assistant for Rap Lyrics', url:'https://www.punchlinator.com/' },
  { icon: <FavoriteBorder />, title: 'Feature 2', description: 'Description of Feature 2', url:'' },
  { icon: <CheckCircleOutline />, title: 'Feature 3', description: 'Description of Feature 3', url:'' },
  // ... more features
];

const FeaturesSection = forwardRef((props, ref) => {
  return (
    <Box id="features" color="background" ref={ref} sx={{ py: 6, px: 3}}>
      <Typography variant="h4" color="primary" component="h2" textAlign="center" gutterBottom>
        Our Solutions
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div whileHover={{scale: 1.05}}>
                  <a
                      href={feature.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{textDecoration: 'none', display: 'block'}}
                  >
                      <Card color sx={{boxShadow: 3, borderRadius: 2}}>
                          <Box sx={{display: 'flex', justifyContent: 'center', pt: 3}}>
                              {feature.icon}
                          </Box>
                          <CardContent>
                              <Typography gutterBottom variant="h5" component="div" textAlign="center">
                                  {feature.title}
                              </Typography>
                              <Typography variant="body1" color="text.secondary" textAlign="center">
                                  {feature.description}
                              </Typography>
                          </CardContent>
                      </Card>
                  </a>
              </motion.div>
          </Grid>
            ))}
      </Grid>
    </Box>
);
});

export default FeaturesSection;
